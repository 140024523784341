import appService from "./appService";

const modulesService = {
    getByUrl(moduleUrl) {
        return appService.get('/modules/get_by_url/' + moduleUrl)
            .then((res) => res.data)
    },
    getDefaultValues(moduleUrl) {
        return appService.get('/' + moduleUrl + '/get_default_values')
            .then((res) => res.data)
    },
    search(moduleUrl, page, data) {
        return appService.post('/' + moduleUrl + '/search/' + page, data)
            .then((res) => res.data)
    },
    getAll(moduleUrl) {
        return appService.get('/' + moduleUrl)
            .then((res) => res.data)
    },
    getList(moduleUrl, columns, conditions) {
        if(conditions) {
            return appService.get('/' + moduleUrl + '/list/' + columns + '/' + conditions)
                .then((res) => res.data)
        } else {
            return appService.get('/' + moduleUrl + '/list/' + columns)
                .then((res) => res.data)
        }
    },
    getListAjax(moduleUrl, columns, filter, conditions) {
        if(conditions) {
            return appService.post('/' + moduleUrl + '/list/' + columns + '/' + conditions, {filter: filter})
                .then((res) => res.data)
        } else {
            return appService.post('/' + moduleUrl + '/list/' + columns, {filter: filter})
                .then((res) => res.data)
        }
    },
    get(moduleUrl, id) {
        return appService.get('/' + moduleUrl + '/' + id)
            .then((res) => res.data)
    },
    create(moduleUrl, data) {
        return appService.post('/' + moduleUrl , data)
            .then((res) => res.data)
    },
    update(moduleUrl, id, data) {
        return appService.put('/' + moduleUrl + '/' + id , data)
            .then((res) => res.data)
    },
    delete(moduleUrl, id) {
        return appService.delete('/' + moduleUrl + '/' + id)
            .then((res) => res.data)
    }
}

export default modulesService;