import Vue from 'vue'
import modulesService from '../services/api/modules'

const moduleModule = {
    state: {
        modules: [], 
        registers: [],
        registersList: [],
        register: [],
        registerFilter: '',
        registerSortColumn: 'id',
        registerSortDirection: 1
    },
    mutations: {
        addModules (state, module) {
            let index = state.modules.findIndex(x => x.id === module.id)

            if(index == -1) {
                state.modules.push(module)
            }
        },
        setRegisters (state, registers) {
            state.registers = registers
        },
        setRegistersList (state, registersList) {
            state.registersList = registersList
        },
        addRegisters (state, register) {
            let index = state.registers.findIndex(x => x.id === register.id)

            if(index == -1) {
                state.registers.push(register)
            }
        },
        updateRegisters (state, register) {
            let index = state.registers.findIndex(x => x.id === register.id)

            if(index > -1) {
                Vue.set(state.registers, index, register)
            }
        },
        deleteRegisters (state, register) {
            let index = state.registers.findIndex(x => x.id === register.id)

            if(index > -1) {
                state.registers.splice(index,1)
            }
        },
        setRegister  (state, register) {
            state.register = register
        },
        setRegisterFilter (state, filter) {
            state.registerFilter = filter
        },
        setRegisterSortColumn (state, sortColumn) {
            state.registerSortColumn = sortColumn
        },
        setRegisterSortDirection (state, sortDirection) {
            state.registerSortDirection = sortDirection
        }
    },
    actions: {
        getModuleByUrl (context, payload) {
            return new Promise((resolve, reject) => {
                modulesService.getByUrl(payload.moduleUrl)
                    .then((module) => {
                        context.commit("addModules", module)
                        resolve(module)
                    })
                    .catch(reject)
            })
        },
        getDefaultValuesByModuleByUrl (context, payload) {
            return new Promise((resolve, reject) => {
                modulesService.getDefaultValues(payload.moduleUrl)
                    .then((object) => {
                        resolve(object)
                    })
                    .catch(reject)
            })
        },
        searchRegisters (context, payload) {
            return new Promise((resolve, reject) => {
                modulesService.search(payload.moduleUrl, payload.page, payload.data)
                    .then((registers) => {
                        context.commit('setRegisters', registers.data)
                        resolve(registers)
                    })
                    .catch(reject)
            })
        },
        getRegisters (context, payload) {
            return new Promise((resolve, reject) => {
                modulesService.getAll(payload.moduleUrl)
                    .then((registers) => {
                        context.commit('setRegisters', registers)
                        resolve(registers)
                    })
                    .catch(reject)
            })
        },
        getRegistersList (context, payload) {
            return new Promise((resolve, reject) => {
                if(!payload.conditions) {
                    payload.conditions = ''
                }
                modulesService.getList(payload.moduleUrl, payload.columns, payload.conditions)
                    .then((registers) => {
                        context.commit('setRegistersList', registers)
                        resolve(registers)
                    })
                    .catch(reject)
            })
        },
        getRegistersListAjax (context, payload) {
            return new Promise((resolve, reject) => {
                modulesService.getListAjax(payload.moduleUrl, payload.columns, payload.filter, payload.conditions)
                    .then((registers) => {
                        context.commit('setRegistersList', registers)
                        resolve(registers)
                    })
                    .catch(reject)
            })
        },
        getRegister (context, payload) {
            return new Promise((resolve, reject) => {
                modulesService.get(payload.moduleUrl, payload.id)
                    .then((register) => {
                        context.commit('setRegister', register)
                        context.commit('updateRegisters', register)
                        resolve(register)
                    })
                    .catch(reject)
            })
        },
        createRegister (context, payload) {
            console.log(payload)
            return new Promise((resolve, reject) => {
                modulesService.create(payload.moduleUrl, payload.data)
                    .then((register) => {
                        context.commit('setRegister', register)
                        context.commit('addRegisters', register)
                        resolve(register)
                    })
                    .catch(reject)
            })
        },
        updateRegister (context, payload) {
            return new Promise((resolve, reject) => {
                modulesService.update(payload.moduleUrl, payload.id, payload.data)
                    .then((register) => {
                        context.commit('setRegister', register)
                        context.commit('updateRegisters', register)
                        resolve(register)
                    })
                    .catch(reject)
            })
        },
        deleteRegister (context, payload) {
            return new Promise((resolve, reject) => {
                modulesService.delete(payload.moduleUrl, payload.id)
                    .then((register) => {
                        context.commit('setRegister', {})
                        context.commit('deleteRegisters', register)
                        resolve(register)
                    })
                    .catch(reject)
            })
        },
    }
}

export default moduleModule