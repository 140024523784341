import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "../views/Login.vue"
import Logout from "../views/Logout.vue"

import CallList from "../views/calls/List.vue"
import CallHistory from "../views/calls/History.vue"
import DoctorShow from "../views/doctors/Show.vue"
import DoctorMe from "../views/doctors/Me.vue"

import ModuleList from "../views/modules/List.vue"
import ModuleAdd from "../views/modules/Add.vue"
import ModuleEdit from "../views/modules/Edit.vue"

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'home', component: Login },
  { path: '/login', name: 'login', component: Login },
  { path: '/logout', name: 'logout', component: Logout },
  { path: '/calls', name: 'callList', component: CallList },
  { path: '/calls/history', name: 'callHistory', component: CallHistory },
  { path: '/:moduleUrl', name: 'module_list', component: ModuleList },
  { path: '/:moduleUrl/add', name: 'module_add', component: ModuleAdd },
  { path: '/doctors/me', name: 'doctorMe', component: DoctorMe },
  { path: '/doctors/:id', name: 'doctorShow', component: DoctorShow },
  { path: '/:moduleUrl/:id/edit', name: 'module_edit', component: ModuleEdit },
  { path: '/:parentModuleUrl/:parentId/:moduleUrl', name: 'module_list', component: ModuleList },
  { path: '/:parentModuleUrl/:parentId/:moduleUrl/add', name: 'module_add', component: ModuleAdd },
  // { path: '/:moduleUrl/:id', name: 'module_show', component: ModuleShow },
]

const router = new VueRouter({
  mode: 'history',
  routes
});
  
export default router

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});